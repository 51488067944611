<script setup lang="ts">
const appConfig = useAppConfig()
</script>

<template>
  <div class="h-screen flex items-center justify-center overlay">
    <ClientOnly>
      <ThemeBackgroundTetris class="w-full h-full -z-10" />
    </ClientOnly>
    <UDashboardLayout class="z-10">
      <UDashboardPanel grow>
        <UDashboardNavbar title="La Tinta" class="z-10 bg-background">
          <template #left>
            <span class="flex items-center gap-3">
              <img
                :src="appConfig.netzo.favicon"
                :alt="appConfig.netzo.title"
                class="h-10 w-auto"
              >
              <h1 class="text-gray-900 dark:text-white text-2xl font-bold truncate">
                {{ appConfig.netzo.title }}
              </h1>
            </span>
          </template>
        </UDashboardNavbar>

        <slot />

        <UDivider />

        <UFooter>
          <template #center>
            <NetzoLogo class="h-10 w-auto" />
          </template>
        </UFooter>
      </UDashboardPanel>
    </UDashboardLayout>
  </div>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>

<style scoped>
:deep([aria-label="Open sidebar"]) {
  display: none;
}
</style>
